<template>
  <v-container class="align-self-start">
    <v-data-table
      :headers="$vuetify.breakpoint.xs ? headersXs : headers"
      :items="items"
      :server-items-length="total"
      :options.sync="options"
      :loading="loading"
      class="elevation-0 align-self-start col-12 pa-0 mb-12 items-data-table"
      item-key="id"
    >
      <template v-slot:top>
        <v-toolbar flat dense>
          <v-toolbar-title>Ventas</v-toolbar-title>
        </v-toolbar>

        <v-form ref="filterForm">
          <v-container fluid class="pt-0">
            <v-row
              align="start"
              justify="start"
              :no-gutters="$vuetify.breakpoint.xs"
            >
              <v-col cols="12" md="6" class="pt-0">
                <v-autocomplete
                  v-model="cliente"
                  :loading="loadingClientes"
                  :items="clientes"
                  :search-input.sync="clientesQuery"
                  single-line
                  hide-details
                  cache-items
                  clearable
                  label="Cliente"
                  placeholder="Buscar cliente"
                  item-text="RazonSocial"
                  return-object
                  @input="setCliente"
                  :no-data-text="noDataText"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" class="pt-0">
                <v-menu
                  v-model="menuDesde"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="computedDesdeFormatted"
                      label="Desde"
                      prepend-icon="mdi-calendar"
                      single-line
                      hide-details
                      clearable
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="filters.desde" @input="menuDesde = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3" class="pt-0">
                <v-menu
                  v-model="menuHasta"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="computedHastaFormatted"
                      label="Hasta"
                      prepend-icon="mdi-calendar"
                      single-line
                      hide-details
                      clearable
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="filters.hasta" @input="menuHasta = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </template>
      <template v-slot:item.Importe="{ item }">
        <span class="text-no-wrap">
          {{ numberFormat(item.Importe, { style: 'currency', currency: 'ARS' }) }}
        </span>
      </template>
    </v-data-table>

    <v-footer
      fixed
      style="bottom: 33px;"
      class="grey-100"
      padless
    >
      <v-card
        flat
        tile
        width="100%"
        class="transparent"
      >
        <v-divider></v-divider>

        <v-row
          :class="{ 'text-right': $vuetify.breakpoint.smAndUp }"
          :justify="$vuetify.breakpoint.smAndUp ? 'end' : 'space-between'"
          :align="$vuetify.breakpoint.smAndUp ? 'center' : 'center'"
          no-gutters
        >
          <v-col
            class="py-2 px-6 text-h6"
            title="Importe Total"
          >
            <span>Total:</span> <span class="text-no-wrap text-monospace green--text font-weight-bold">{{ numberFormat(importeTotal, { style: 'currency', currency: 'ARS' }) }}</span>
          </v-col>
        </v-row>

        <v-divider></v-divider>
      </v-card>
    </v-footer>
  </v-container>
</template>

<script>

import _ from 'lodash'

export default {
  data: () => ({
    loading: false,
    dialog: false,
    loadingClientes: false,
    clientes: [],
    cliente: null,
    idCliente: -1,
    clientesQuery: '',
    menuDesde: false,
    menuHasta: false,
    filters: {
      desde: new Date().toISOString().substr(0, 10),
      hasta: new Date().toISOString().substr(0, 10),
      pagina_solicitada: 1,
      registrosPorPagina: 10
    },
    search: '',
    headers: [
      { text: 'Fecha', value: 'Fecha', sortable: false },
      { text: 'Cliente', value: 'Razon Social', sortable: false },
      { text: 'Importe Total', value: 'Importe', align:'right', sortable: false },
    ],
    headersXs: [
      { text: 'Fecha', value: 'Fecha', sortable: false },
      { text: 'Cliente', value: 'Razon Social', sortable: false },
      { text: 'Importe Total', value: 'Importe', align:'right', sortable: false },
    ],
    items: [],
    total: 0,
    importeTotal: 0,
    options: {},
    item: {},
    noDataText: 'Escriba para buscar...',
  }),

  computed: {
    computedDesdeFormatted: {
      get: function () {
        if (!this.filters.desde) return null

        const [year, month, day] = this.filters.desde.split('-')

        return `${day}/${month}/${year}`
      },
      set: function (newValue) {
        this.filters.desde = newValue
      }
    },
    computedHastaFormatted: {
      get: function () {
        if (!this.filters.hasta) return null

        const [year, month, day] = this.filters.hasta.split('-')

        return `${day}/${month}/${year}`
      },
      set: function (newValue) {
        this.filters.hasta = newValue
      }
    },
  },

  watch: {
    options: {
      handler () {
        this.query()
      },
      deep: true,
    },

    clientesQuery (val) {
      if (!this.cliente && val) {
        this.noDataText = 'Buscando...'
        this.debounceClientes(val)
      }
    },

    'idCliente': function () {
      this.refresh()
    },

    'filters.desde': function () {
      this.refresh()
    },

    'filters.hasta': function () {
      this.refresh()
    },
  },

  methods: {
    debounceClientes: _.debounce(function (val) {
      this.queryClientes(val)
    }, 1000),

    queryClientes (val) {
      this.loadingClientes = true;

      this.$soapClient.call('listarClientes', {
        filtro: {
          consulta: val,
        },
      })
      .then((response) => {
        this.clientes = response.data.resultado;

        if (!this.clientes.length) {
          this.noDataText = 'No hay datos disponibles'
        }
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loadingClientes = false;
      })
    },

    setCliente () {
      if (!this.cliente) {
        this.idCliente = -1
      } else {
        this.idCliente = this.cliente.Id
      }

      const parsedCliente = JSON.stringify(this.cliente);
      sessionStorage.setItem('ventasCliente', parsedCliente);
    },

    refresh: function () {
      this.options.page = 1
      this.query()
    },

    query: function () {
      this.loading = true

      this.filters.pagina_solicitada = this.options.page
      this.filters.registrosPorPagina = this.options.itemsPerPage

      this.$soapClient.call('listarVentas', {
        idCliente: this.idCliente,
        filtro: this.filters,
      })
      .then((response) => {
        this.items = response.data.resultado
        this.total = this.items.lenght
        this.importeTotal = response.data.importeTotal

        const parsedFilters = JSON.stringify(this.filters);
        sessionStorage.setItem('ventasFilters', parsedFilters);
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loading = false
      })
    },

    numberFormat(value, options) {
      const numberFormat = new Intl.NumberFormat('es-AR', options)

      return numberFormat.format(value)
    },
  },

  mounted() {
    if (sessionStorage.getItem('ventasFilters')) {
      try {
        this.filters = JSON.parse(sessionStorage.getItem('ventasFilters'));
      } catch(e) {
        sessionStorage.removeItem('ventasFilters');
      }
    }

    if (sessionStorage.getItem('ventasCliente')) {
      try {
        this.cliente = JSON.parse(sessionStorage.getItem('ventasCliente'));

        if (!this.cliente) {
          this.idCliente = -1
        } else {
          this.idCliente = this.cliente.Id
          this.queryClientes(this.cliente.RazonSocial)
        }
      } catch(e) {
        sessionStorage.removeItem('ventasCliente');
      }
    }
  },
};
</script>
